<template>
  <div class="cnt-modal">
    <div class="text-center">
      <ion-label class="fw-600 fs-3" :color="isCancelPayment ? 'danger' : ''">{{ title }}</ion-label>
    </div>
    <div class="my-3 mx-2 text-center mb-4">
      <ion-label>{{ subTitle }}</ion-label>
    </div>
    <div class="line-horizontal"></div>
    <div class="d-flex flex-row align-center align-content-space-between">
      <ion-button
        expand="block"
        fill="clear"
        class="w-100"
        size="small"
        :color="isCancelPayment ? 'primary' : 'danger'"
        @click="$emit('on-no')"
        >{{ isCancelPayment ? $t('stay') : $t('no') }}</ion-button
      >
      <div class="line-vertical"></div>
      <ion-button
        expand="block"
        fill="clear"
        class="w-100 fw-500"
        size="small"
        :color="isCancelPayment ? 'danger' : 'primary'"
        @click="$emit('on-yes')"
        >{{ isCancelPayment ? $t('confirm') : $t('yes') }}</ion-button
      >
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    isCancelPayment: {
      type: Boolean,
      default: false
    }
  },

  emits: ['on-no', 'on-yes']
});
</script>
<style lang="scss" scoped>
.cnt-modal {
  padding: 1.5rem 0 0 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.line-vertical {
  height: 40px;
  width: 1px;
  background-color: #e0e0e0;
  background: #e0e0e0;
}

.line-horizontal {
  height: 1px;
  width: 100%;
  background-color: #e0e0e0;
  background: #e0e0e0;
}
</style>
